<template>
  <div class="index">
    <van-nav-bar
      :title="'跟进客户列表'"
      left-text="返回"
      left-arrow
      fixed
      placeholder 
      @click-left="onClickLeft"
    >
      <div slot="right" class="nav-right-dropdown">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="checkType" :options="option"  @change="changeCheckType"/>
        </van-dropdown-menu>
      </div>
    </van-nav-bar>
    <div class="content">
      <!-- <div class="header__right">
        <div class="switch">
          <a-select style="min-width: 80px" size="small" :value="checkType" @change="changeCheckType">
            <a-select-option :key="1">今天</a-select-option>
            <a-select-option :key="2">昨天</a-select-option>
            <a-select-option :key="3">前天</a-select-option>
          </a-select>
        </div>
      </div> -->
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell-group inset :border="false" v-for="(item, index) in list" :key="index" class="list-item" @click="pushUserEmployee(item.contact_id,item.employee_id)">
          <van-cell :border="false" style="align-items: center;" is-link>
            <template #title>
              <span class="custom-title list-item-title">
                <van-image
                  width="44"
                  height="44"
                  fit="cover"
                  round
                  :src="item.contact.avatar"
                />
                <div>
                  {{ item.contact.name }}
                  <div class="time" style="color: #999">提醒时间 {{ item.execution_time }}</div>
                </div>
              </span>
            </template>
            <template>
              <span class="list-item-value">查看客户</span>
            </template>
          </van-cell>
        </van-cell-group>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('@/assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
  </div>
  
</template>
<script>
import Vue from 'vue'
import { DropdownMenu, DropdownItem } from 'vant'
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
import { pushUser,pushUserEmployee } from '@/plugins/utils'
import { waitFollowTip } from '@/api/waitFollowTip'
export default {
  data() {
    return {
      pushUser,
      pushUserEmployee,
      checkType: 1,
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,

      option: [
        { text: '今天', value: 1 },
        { text: '昨天', value: 2 },
        { text: '前天', value: 3 },
      ],
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.employee_id = this.$route.query.employee_id
    this.startDate = this.dateFormat(new Date())
    this.endDate = this.dateFormat(new Date())
  },
 
  methods: {
    checkDetail (item) {
      pushUser(item)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    changeCheckType (v) {
      this.checkType = v
      switch (v) {
        case 1:
          this.startDate = this.dateFormat(new Date())
          this.endDate = this.dateFormat(new Date())
          break
        case 2:
          this.startDate = this.dateFormat(new Date() - (86400 * 1 * 1000))
          this.endDate = this.dateFormat(new Date() - (86400 * 1 * 1000))
          break
        case 3:
          this.startDate = this.dateFormat(new Date() - (86400 * 2 * 1000))
          this.endDate = this.dateFormat(new Date() - (86400 * 2 * 1000))
          break
      }
      this.page =  1
      this.list = []
      this.onLoad()
    },
    onLoad () {
      this.loading = true
      waitFollowTip.contact({
        employee_id: this.employee_id,
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  
  .nav-right-dropdown {
    /deep/.van-dropdown-menu__bar {
      background-color: unset;
      box-shadow: none;

      .van-dropdown-menu__title {
        color: #fff !important;
      }
    }
  }

  .content {

    // .header__right {
    //   text-align: right;
    //   margin-bottom: 10px;

    //   .svg-icon {
    //     vertical-align: -0.15em;
    //     fill: currentColor;
    //     overflow: hidden
    //   }

    //   .switch {
    //     display: flex;
    //     justify-content: flex-end;
    //     align-items: center;

    //     .switch__item--active {
    //       font-weight: 500;
    //       color: #000;
    //     }

    //     .switch__item {

    //       padding: 0 3px;
    //       margin-left: 7px;
    //       color: #8c8c8c;
    //       font-size: 12px;
    //     }
    //   }
    // }

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 12px 10px;

    .list-item {
      margin-bottom: 8px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);
      // border: 1px solid #F1f2f5;

      /deep/.van-cell {
        background-color: #fff;
        padding: 10px;

        .van-cell__value {
          flex: unset;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      .time {
        font-size: 12px;
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        font-size: 12px;
        color: #1989fa;
      }

      /deep/.van-cell__right-icon {
        // vertical-align: bottom;
        // font-size: 12px;
        height: auto;
        color: #ddd;
      }
    }      
  }
}
</style>
