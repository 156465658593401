import request from '@/utils/request'

/**
 * 待跟进客户提醒
 */
export class waitFollowTip {
  // 客户列表
  static contact (params) {
    return request({
      url: '/waitFollowTip/contact',
      method: 'get',
      params
    })
  }
  // 销售
  static employee (params) {
    return request({
      url: '/waitFollowTip/employee',
      method: 'get',
      params
    })
  }

}